<template>
  <main>
    <PageTitleContainer>
      <PageTitle removeMargin>Modifica produttore {{ producer ? producer.name : '' }}</PageTitle>

      <div class="mb-3 text-right">
        <router-link class="btn btn-outline-secondary" :to="{ name: 'suppliers.producers' }">Torna indietro</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <ProducerForm v-if="producer" :value="producer" :feedback.sync="feedback" @submitForm="saveProducer" edit />
    </div>
  </main>
</template>

<script>

import $api from '@/libs/Api/services/api.js';

import PageTitle from '@/views/components/Private/PageTitle.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import ProducerForm from '@/views/components/Form/ProducerForm.vue';

export default {
  components: {
    PageTitle,
    PageTitleContainer,
    ProducerForm,
  },
  data () {
    return {
      producer: null,
      feedback: null,
    };
  },
  methods: {
    success () {
      this.feedback = true;
      this.$router.replace({ name: 'suppliers.producers' });
    },
    failed (err) {
      this.feedback = false;
      this.$log.error(err);
    },
    saveProducer (producerForm) {
      const producer = { ...producerForm };

      this.$api.updateProducer(producer.id, producer)
        .then(() => this.success())
        .catch(err => this.failed(err))
      ;
    },
  },
  async beforeRouteEnter (to, from, next) {
    $api.fetchProducer(to.params.id)
      .then(res => {
        return next(vm => {
          vm.producer = res?.data || {};
        });
      })
      .catch(err => {
        return next(vm => vm.$log.error(err));
      })
    ;
  },
};

</script>
